import React, { Component } from "react";
import axios from "axios";
import {
  getCDNUrl,
  loginUser,
  handleGoogleEvents,
  tempUser,
} from "./HelperFunctions";
import { Globals } from "./constants";
const queryString = require("query-string");

class AuthenticationForOmantel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      msisdn: "",
      pin: "",
      btnTxt: "Confirm",
      btnDisbaled: false,
      message: "",
      isPinSent: false,
      isShowError: false,
      isOpenWebOrApp: false,
      isMenaDLink: localStorage.getItem("isMenaDLink") ? true : false,
      appName: localStorage.getItem("deviceUDID")
        ? localStorage.getItem("deviceUDID")
        : null,
      isSubscribed: 0,
    };

    this.apiPath = Globals.NodeApi + "api/";
    this.handleKeypress = this.handleKeypress.bind(this);
    this.sendPin = this.sendPin.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.loginValuesChange = this.loginValuesChange.bind(this);
  }

  async componentDidMount() {
    let cgObject = JSON.parse(localStorage.getItem("cgObject")),
      redirectTo = "/login",
      logRequest = { cgObject: cgObject, params: this.props.location.search };

    let params = queryString.parse(this.props.location.search);

    //http://omantel-cg.timwe.com/cg/index?msisdn=96891276400&correlatorId=4bbcee72b5c74f78be846599bee88c47&trxId=1366947094&buyChannel=WEB&signature=eFvHY1eLkUfRt9Dglj%2F9X77mjUQ5Q2Nl4fhf2hQHwUECU%2FGjl%2FNMtLN%2B7WuG1gTHEYL8n9l3b3C5Ls%2FMxo3esfdsprEWiZM0xYkdB7Gc1TNojnE9UGuVqSStDTinbGVsrTLGEWQ3Ggju3ksh8m6VJg%3D%3D&validateUrl=http%3A%2F%2Fomantel-cg.timwe.com%2Fcg%2FvalidateToken&empelloScript=https%3A%2F%2Fantifraud.empello.net%2Fdyfkxvkc%2Fnriafgjh-live.js

    //   http://localhost:3000/omantel-authentication/?MM_OM_Key=9984juu766644&MM_OM_Success=1
    //   http://localhost:3000/omantel-authentication?MM_OM_Key=9a781fa35856452fab65c5c0c9ce946b&MM_OM_Success=0&MM_OM_Message=ERROR_ON_SUBSCRIPTION,_FRAUDSTOP&MM_OM_MSISDN=96891276400&MM_OM_ClientKEY=96891276400
    //  http://localhost:3000/omantel-authentication?MM_OM_Key=9a781fa35856452fab65c5c0c9ce946b&MM_OM_Success=1&MM_OM_MSISDN=96891276400&MM_OM_ClientKEY=

    if (params.MM_OM_Success == "1") {
      let phone = params.MM_OM_MSISDN;
      let serverResponse;

      this.setState({
        msisdn: params.MM_OM_MSISDN,
      });

      let url =
        this.apiPath +
        "login_or_sendPin?telco=omantel&phone=" +
        phone +
        "&sub_type=";

      serverResponse = await axios.get(url, {});

      this.setState({
        isLoading: false,
      });
      serverResponse = serverResponse.data;
      // new check added for time being
      if (serverResponse.generic_response.message === "Invalid Phone number") {
        this.setState({
          btnTxt: "Confirm",
          btnDisbaled: false,
          message: "You have entered invalid mobile number",
        });

        return;
      }

      if (serverResponse) {
        localStorage.setItem(
          "isSocialProfileAttached",
          serverResponse.remote_response && serverResponse.remote_response.user
            ? serverResponse.remote_response.user.is_social_profile_attached
            : "false"
        );

        let generic_response = {};

        if (
          (Object.keys(serverResponse).length === 0 &&
            serverResponse.constructor === Object) ||
          (!serverResponse.remote_response && !serverResponse.generic_response)
        ) {
          //empty response, means no response from  node server
          generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
          // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
          // alert("Something went wrong, please check your network.");
        } else if (
          !serverResponse.status &&
          serverResponse.generic_response &&
          serverResponse.generic_response.call_type &&
          serverResponse.generic_response.call_type === "appstart" &&
          serverResponse.error
        ) {
          //appstart failed, remote server must not be responding
          generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
          // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
          // alert("Something went wrong, please check your internet connection.");
        } else if (!serverResponse.status) {
          //server responded but ERROR !
          this.setState({
            message: serverResponse.generic_response.message,
            btnTxt: "Confirm",
            btnDisbaled: false,
          });
        } else {
          generic_response = serverResponse.generic_response;
        }

        if (generic_response.subscribed) {
          this.setState({ message: "" });

          generic_response.phone = phone;
          generic_response.telco = this.state.telco;
          localStorage.setItem("user_logged_in", true);
          localStorage.setItem("user", JSON.stringify(generic_response));

          handleGoogleEvents(
            "Login",
            `Login-${this.state.telco}`,
            `User-Logged_In`
          );

          loginUser(generic_response);
          window.location = document.documentURI;
          return false;
        }

        if (
          serverResponse.status &&
          !serverResponse.generic_response.subscribed
        ) {
          //pin sent
          this.setState({
            isPinSent: true,
          });
        }
      }
    } else {
      this.setState({
        isLoading: false,
        isShowError: true,
      });
    }
  }

  loginValuesChange = (event) => {
    let lastKeyStroke = event.target.value[event.target.value.length - 1];
    let value = event.target.value;
    let setState = {};
    if (
      event.target.name === this.state.inputName &&
      this.state.prefill &&
      value.length < this.state.prefill.length
    ) {
      setState = {
        [event.target.name]: this.state.prefill,
        inputValue: this.state.prefill,
      };
    } else {
      setState = {
        [event.target.name]:
          (value.length && isNaN(lastKeyStroke)) || lastKeyStroke === " "
            ? this.state[event.target.name]
            : value,
        inputValue: value,
      };
    }
    this.setState(setState);
  };

  handleKeypress = function(e) {
    if (e.key === "Enter") {
      if (this.state.gPayFlow) {
        if (this.state.showPinFlow) this.confirmPinGPay();
        else this.sendPinGPay();
      } else {
        this.login();
      }
    }
  };

  async sendPin() {
    this.setState({
      btnTxt: "Please Wait ...",
      btnDisbaled: true,
    });

    let url = `${this.apiPath}confirmpin?telco=omantel&phone=${
      this.state.msisdn
    }&pin=${this.state.pin}&sub_type=`;
    let serverResponse;

    serverResponse = await axios.get(url, {});

    serverResponse = serverResponse.data;

    if (serverResponse) {
      let generic_response = {};
      if (
        Object.keys(serverResponse).length === 0 &&
        serverResponse.constructor === Object
      ) {
        // no server response, ERROR ! login with a temporary user
        //  generic_response = tempUser(phone);
        this.setState({
          message: "Something went wrong!",
          btnTxt: "Confirm",
          btnDisbaled: false,
        });
      } else if (!serverResponse.status) {
        this.setState({
          message: serverResponse.generic_response.message,
          btnTxt: "Confirm",
          btnDisbaled: false,
        });
      } else {
        generic_response = serverResponse.generic_response;
      }

      if (generic_response.subscribed) {
        this.setState({ message: "" });
        // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
        generic_response.phone = this.state.msisdn;
        generic_response.telco = "omantel";
        this.setState({
          isSubscribed: serverResponse.remote_response.subscribe_status,
        });
        localStorage.setItem("user_logged_in", true);
        localStorage.setItem("user", JSON.stringify(generic_response));

        handleGoogleEvents(
          "Login",
          `Login-${this.state.telco}`,
          `Pin-Confirm-Ok-Subscribed`
        );

        loginUser(generic_response);
        // window.history.back();

        if (this.state.isMenaDLink) {
          this.setState({ showPinFlow: false, isOpenWebOrApp: true });
        } else {
          window.location = document.documentURI;
        }
      }
    }
  }

  goToLogin() {
    this.state.isMenaDLink
      ? (window.location.href = `/login/true/${
          this.state.appName
        }/${localStorage.getItem("filledNumber")}`)
      : (window.location.href = "/login");
  }

  renderPin = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Pin Verification{" "}
        </div>
        <h3 className="text-center mb-3">
          We have sent PIN code via SMS to following number
        </h3>
        <h3 className="text-center mb-5"> {this.state.msisdn} </h3>
        {/* <div>{ this.renderTelcosForPin()}</div> */}

        <div className="">
          {/* {!this.state.showResendButton && (
              <div className="text-center mb-3 d-none">
                <Timer
                  initialTime={10000}
                  direction="backward"
                  timeToUpdate={500}
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => this.setState({ showResendButton: true }),
                    },
                  ]}
                >
                  <Timer.Seconds />
                </Timer>
              </div>
            )} */}

          <input
            name={"pin"}
            //className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            ref={this.textInput}
            autoFocus={true}
            placeholder={"Enter your pin code here"}
            value={this.state.pin}
            onChange={this.loginValuesChange}
            onKeyPress={this.handleKeypress}
            autoComplete="off"
            style={{
              width: 100 + "%",
              textAlign: "left",
              backgroundColor: "white",
              borderBottomColor: "lightgray",
              paddingLeft: "10px",
              paddingBottom: 8 + "px",
              borderRadius: 0,
              height: "65px",
              border: "none",
              background: "#F8F7F7",
              outline: "none",
              fontSize: "1.6rem",
            }}
            maxLength={`4`}
            // max="6"
          />

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.sendPin}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>

          {/* <h2 className="text-center mt-5"> Didn’t receive Code? </h2>
            <h1
              className={
                "text-center mt-2 " +
                (this.state.showResendButton
                  ? "clickable-text"
                  : "unclickable-text")
              }
              onClick={() => {
                this.state.showResendButton && this.sendPinAgain();
              }}
            >
              {" "}
              Resend Pin{" "}
            </h1> */}

          <p
            className={"text-center w-50"}
            style={{ margin: "15px auto 0 auto" }}
          >
            {this.state.message}
          </p>

          {
            <>
              <p
                className={"text-center w-50 font-weight-bold"}
                style={{ margin: "0 auto 0 auto" }}
              >
                250 Baisa every day (Subject to 5% Value added Tax).
              </p>
              <br />
              <p
                className={"text-center w-50 font-weight-bold"}
                dir="rtl"
                lang="ar"
                style={{ margin: "0 auto 0 auto" }}
              >
                250 بيسة عماني في اليوم (تخضع إلى 5% ضريبة القيمة المضافة).
              </p>
            </>
          }

          {
            <>
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                style={{
                  margin: `30px auto 0 auto`,
                }}
              >
                {`Welcome to CricWick.
        For Omantel users you will be charged 250 Baisa every day (Subject to 5% Value added Tax).
        Your subscription will renew automatically until cancelled.
        To cancel the service, send unsub d to 92804.
        To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.
        The content inside this service is in English.`}
              </p>
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                dir="rtl"
                lang="ar"
                style={{
                  margin: `30px auto 0 auto`,
                }}
              >
                {`
          مرحبا بكم في CricWick!
          سعر الاشتراك هو 250 بيسة عماني في اليوم (تخضع إلى 5% ضريبة القيمة المضافة) لمشتركي Omantel.
          تتجدد هذه الخدمة تلقائيًا حتى يتم الغاؤها.
          للإلغاء أرسل unsub d إلى 92804 لعملاء Omantel.
          لاستخدام هذه الخدمة، يجب أن يكون عمرك 18 عامًا أو أن تحصل على إذن من والديك أو أي شخص مخول بدفع فاتورتك.
          محتوى هذه الخدمة في اللغة الإنجليزية.
        `}
              </p>
            </>
          }
        </div>
      </div>
    );
  };

  renderError = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Error{" "}
        </div>
        <h3 className="text-center mb-3">
          You can not subscribe to this service
        </h3>
        <h3 className="text-center mb-5">
          {" "}
          You can subscribe with another number{" "}
        </h3>
        <button
          className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={this.goToLogin}
        >
          Go to Login
        </button>
      </div>
    );
  };

  renderOpenWebOrApp = () => {
    return (
      <div>
        <h3 className="text-center mb-3">
          You have been subscribed successfully
        </h3>
        <div className="text-center m-3">
          <img
            src={require("../src/images/success.png")}
            alt="success"
            height={200}
            width={200}
          />
        </div>
        <button
          className="submit border-0 w-30 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={() => {
            window.location.href = "/";
            localStorage.setItem("isMenaDLink", false);
          }}
        >
          Continue on Web
        </button>
        <h3 className="text-center mt-5"> OR </h3>
        <button
          className="submit border-0 w-30 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={() => {
            let link = `http://cricwick.net/welcome/${localStorage.getItem(
              "filledNumber"
            )}/${this.state.isSubscribed}/omantel`;
            console.log("APP", link);
            window.open(link, "_self");
          }}
        >
          Open App
        </button>
      </div>
    );
  };

  render() {
    return (
      <div id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
            {this.state.isPinSent
              ? this.renderPin()
              : this.state.isOpenWebOrApp
                ? this.renderOpenWebOrApp()
                : this.state.isShowError
                  ? this.renderError()
                  : null}
          </div>
        )}
      </div>
    );
  }
}

export default AuthenticationForOmantel;
